export enum Pages {
  default = 'grants',
  aboutUs = 'help/sobre-tamiz',
  legalPolicy = 'help/aviso-legal',
  privacityPolicy = '/help/politica-de-privacidad',
  useTerms = '/help/terminos-y-condiciones-de-uso',
  cookiesPolicy = '/help/politica-de-cookies',
  grantList = 'grants',
  myGrants = 'my-grants',
  grantDetail = 'grants/{id}',
  help = 'https://evmgroup.odoo.com/knowledge/article/196',
  me = 'me',
  onBoarding = 'on-boarding',
  onBoardingPassword = 'on-boarding/password',
  onBoardingProcess = 'on-boarding/process',
  onBoardingRegions = 'on-boarding/regions',
  onBoardingCPVs = 'on-boarding/cpvs',
  settings = 'settings',
  signIn = 'auth/sign-in',
  signUp = 'auth/sign-up',
  dashboard = 'https://admin.tamiz.es',
  subscription = 'planes',
  searchGrantList = 'demo',
  searchGrantDetail = 'demo/{id}',
}
